import React from 'react';
import { FormattedMessage } from 'react-intl';

export default class MenuStart extends React.PureComponent {
  render() {
    return (
        <div>
          <a href="#" onClick={(e) => {e.preventDefault(); this.props.onSignUp();}} class="panel-title">
            <FormattedMessage id="button_sign_up" defaultMessage="Sign up" description="Create account button [Sign Up]" />
          </a>
          &nbsp;
          {/* <a href="#" onClick={(e) => {e.preventDefault(); this.props.onSettings();}}><i className="material-icons">settings</i></a> */}
        </div>
    );
  }
};
